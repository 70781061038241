import { products, resources } from "./content/navbar";
import { useTranslation } from 'react-i18next';

import KincodeLogo from "./../../assets/logo/kincode-logo.svg";
import Cta from "./../cta/Cta";

import { BiWorld } from "react-icons/bi";
import { IoChevronForward } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function DesktopNav() {
    const { t, i18n } = useTranslation();

    const [currentMenu, setCurrentMenu] = useState(undefined);
    const [selected, setSelected] = useState(undefined);

    let onProductClicked = () => {
        currentMenu===undefined || currentMenu===resources ? setCurrentMenu(products) : setCurrentMenu(undefined);
        setSelected("product");
    };

    // let onResourcesClicked = () => {
    //     currentMenu===undefined || currentMenu===products ? setCurrentMenu(resources) : setCurrentMenu(undefined);
    //     setSelected("resources");
    // };
    
    let location = useLocation();

    useEffect(() => {
        setCurrentMenu(undefined);
        
        if(location.pathname === t("routes.layout") || location.pathname === t("routes.home")) {
            setSelected(undefined);
        }
    }, [location.pathname]);


    const [currentLang, setCurrentLang] = useState(i18n.language);

    useEffect(() => {

        i18n.changeLanguage(currentLang);
    
    }, [currentLang])
    

    return(
        <>
            <div className="h-14 w-full bg-[#BBF7D0] flex flex-row">
                <div className="w-full max-w-screen-xl px-5 flex flex-row justify-between items-center m-auto">
                    <Cta text={t("buttons.tryKincode")} btnType="tertiary-black" goTo="routes.contact" iconRight={<IoChevronForward />} />
                    <div className="flex flex-row gap-5">
                        <Link to="//platform.kincode.app" target="_blank" className="hover:text-[#107D35] hover:underline" >{t("buttons.login")}</Link>

                        <label htmlFor="lang" className="flex flex-row">
                            <BiWorld className="w-4 h-auto" />
                            <select defaultValue={currentLang} className="bg-none border-none bg-[#BBF7D0] active:border-none focus:outline-none cursor-pointer" onChange={(e) => {setCurrentLang(e.target.value)}}>
                                <option value="es-ES" className="bg-white">
                                    <Cta text="navbar.translate.spanish" btnType="tertiary-black" />
                                </option>
                                <option value="en-US" className="bg-white">
                                    <Cta text="navbar.translate.english" btnType="tertiary-black" />
                                </option>
                            </select>

                        </label>
                        
                    </div>

                </div>
            </div>

            <nav className="w-full h-20 flex flex-row sticky top-0 bg-white z-40">
                <div className="w-full max-w-screen-xl px-5 flex flex-row justify-between items-center m-auto">
                    <Link to={t("routes.home")}>
                        <img className="max-h-10 h-10 w-auto" src={KincodeLogo} alt="Kincode logo" />
                    </Link>
                    <div className="flex flex-row gap-5">
                        <Cta text={t("navbar.menu.product")} btnType="nav" click={onProductClicked} goTo="#" iconRight={<IoChevronDownSharp />} rotateAnimation={true} isSelected={selected === "product"} />
                        {/* <Cta text={t("navbar.menu.resources")} btnType="nav" click={onResourcesClicked} goTo="#" iconRight={<IoChevronDownSharp />} rotateAnimation={true} isSelected={selected === "resources"} /> */}
                        <Cta text="navbar.menu.about" btnType="nav" click={() => {setSelected('about'); setCurrentMenu(undefined)}} goTo="routes.about" isSelected={selected === 'about'} />
                        <Cta text="navbar.menu.solutions" btnType="nav" click={() => {setSelected('solutions'); setCurrentMenu(undefined)}} goTo="routes.toxicCulture" isSelected={selected === 'solutions'} />
                        {/* <Cta text="navbar.menu.price" btnType="nav" click={() => {setSelected('price'); setCurrentMenu(undefined)}} goTo="routes.pricing" isSelected={selected === 'price'} /> */}
                        <Cta text="navbar.menu.contact" btnType="nav" click={() => {setSelected('contact'); setCurrentMenu(undefined)}} goTo="routes.contact" isSelected={selected === 'contact'} />
                    </div>
                    <div className="flex flex-row gap-5">
                        <Cta text="buttons.free" btnType="secondary" goTo="routes.contact" />
                        <Cta text="buttons.demo" btnType="primary" goTo="routes.contact" />
                    </div>
                </div>
            </nav>

            {currentMenu &&
                <div className="flex flex-row sticky top-20 bg-white z-40">
                    <div className="w-full max-w-screen-xl p-5 flex flex-row gap-10 justify-between m-auto text-left">
                        {currentMenu?.map((product) => {
                            return(
                                <div className="flex flex-col px-5 w-fit max-w-[295x] border-l border-slate-200">
                                    <NavLink to={t(product.goTo)} className="font-bold hover:text-[#107D35]">{t(product.title)}</NavLink>
                                    <ul className="flex flex-col">
                                        {product.elements.map((element) => {
                                            return(
                                                <li>{element}</li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        
        </>
    )
}